.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.fs-14p {
  font-size: 14px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;

}

.bottom-10 {
  bottom: 10px;
}

.end-20 {
  right: 20px;
}

.start-20 {
  left: 20px;
}

.m-40p {
  margin: 40px;
}

.mt-auto {
  margin-top: auto;
}

.mt-3p {
  margin-top: 3px;
}

.mt-5p {
  margin-top: 5px;
}

.mt-20p {
  margin-top: 20px;
}

.mt-80p {
  margin-top: 80px;
}

.mt-100p {
  margin-top: 100px;
}

.mb-2p {
  margin-bottom: 2px;
}

.mb-3p {
  margin-bottom: 3px;
}

.mb-5p {
  margin-bottom: 5px;
}

.mb-10p {
  margin-bottom: 10px;
}

.mb-20p {
  margin-bottom: 20px;
}

.mr-5p {
  margin-right: 5px;
}

.mr-30p {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.p-10p {
  padding: 10px;
}

.plr-20p {
  padding-left: 20px;
  padding-right: 20px;
}

.mw-415p {
  max-width: 415px;
}

.w-0p {
  width: 0px;
}

.w-20p {
  width: 20px;
}

.w-100p {
  width: 100px;
}

.w-140p {
  width: 140px;
}

.w-200p {
  width: 200px;
}

.w-300p {
  width: 300px;
}

.w-3 {
  width: 3%;
}

.w-17 {
  width: 17%;
}

.w-19 {
  width: 19%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-73 {
  width: 73%;
}

.w-78 {
  width: 78%;
}

.w-80 {
  width: 80%;
}

.w-95 {
  width: 95%;
}

.w-96 {
  width: 96%;
}

.w-97 {
  width: 97%;
}

.w-98 {
  width: 98%;
}

.mh-75p {
  max-height: 75px;
}

.h-0p {
  height: 0px;
}

.h-10p {
  height: 10px;
}

.h-80p {
  height: 80px;
}

.h-100p {
  height: 100px;
}

.h-140p {
  height: 140px;
}

.h-195p {
  height: 195px;
}

.h-200p {
  height: 200px;
}

.h-210p {
  height: 210px;
}

.h-222p {
  height: 222px;
}

.h-30 {
  height: 30%;
}

.h-70 {
  height: 70%;
}

.border-cmr {
  border-color: #9b9a9a;
  border: 1px solid;
}

.textarea-cmr {
  resize: none;
}

.fs-cmr-sign {
  font-size: 9px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.v-align-m {
  vertical-align: "middle";
}



.p-10 {
  padding: 10px;
}

.pl-10 {
  padding-left: 10px;
}
.pt-10 {
  padding-top: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.w-90 {
  width: 90%;
}

.mr-5 {
  margin-right: 5px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-10 {
  margin-right: 10px;
}

.w-80 {
  width: 80px;
}

.w-170 {
  width: 170px;
}

.custom-spinner-loading-overlay {
  position: fixed; /* Posición fija en toda la pantalla */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center; /* Centra horizontalmente el spinner */
  align-items: center; /* Centra verticalmente el spinner */
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente negro */
  z-index: 9999; /* Asegura que el spinner esté por encima de otros elementos */
}

