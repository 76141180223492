/* Styles go here */
.App{
  width: 100vw;
  height: 100vh;
}

body{
  margin: 0;
  padding: 0;
}
/* .Navbar{
  background-color: #EEEEEE;
  width: 100%;
  height: 80px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
}  */
/* Estilos para pantallas grandes */
.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSide {
  display: flex;
  align-items: center;
}
.custom-navbar {
  margin-top: -30px
 }
.rightSide {
  /* Estilos para el lado derecho si es necesario */
}

.links {
  display: flex;
  gap: 20px;
  align-items: center; /* Centrar los enlaces verticalmente */
}

/* Estilos para pantallas más pequeñas */
@media screen and (max-width: 768px) {
 .Navbar {
      flex-direction: column;
  }

 .leftSide,.rightSide {
      width: 100%;
      text-align: center;
  }

 .rightSide {
      text-align: center;
  }

 .links {
      display: flex;
      flex-direction: column;
      gap: 10px; /* Ajustar el espaciado entre enlaces en pantallas pequeñas */
      align-items: center; /* Asegurar que los enlaces estén centrados */
  }

 .links.active {
      display: flex;
  }

 .links a {
      font-size: 14px; /* Ajustar el tamaño de fuente según sea necesario */
      margin: 5px 0; /* Ajustar los márgenes entre los enlaces */
  }
}


.h-47-px{
  height: 47px;
}

.nav-btn{
    width: 80%;
    border: none;
    text-decoration: none;
    font-size: 20px;
    color: #7F86DE;
}

.nav-btn:hover{
    background-color: #EEEEEE;
    color: #7F86DE;
}

.btn.active{
    background-color: #EEEEEE;
    color: #7F86DE;
}
.color-p{
  color: #fd0e35
}

.ul-style-none{
    list-style-type: none;
}
.tx-al-top{
    text-align: top;
}
.tx-al-mid{
  text-align: middle;
}

.w-5{
    width: 5%;
}
.w-30{
    width: 30%;
}

.w-60{
    width: 60%;
}
.w-67{
  width: 67%;
}
.bg-rep{
    background-repeat: no-repeat;
}
.bg-sz{
    background-size: contain;
}
.bg-ps-tp{
    background-position: top;
}
.h-42-px{
    height: 42px;
}
.h-45-px{
  height: 45px;
}
.h-47-px{
  height: 47px;
}
.h-80{
    height: 80%;
}
.h-78-px{
  height: 78.8px;
}
.m-2{
  margin: 2%;
}
.m-5{
    margin: 5%;
}
.me-sm-5{
    margin-right: 5px;
}

.me-sm-10{
  margin-right: 10px;
}
.me-sm-85{
  margin-right: 85px;
}
.me-mr-20{
  margin-right: 20px;
}
.me-mr-100{
  margin-right: 100px;
}
.mb-20-px{
    margin-bottom: 20px;
}
.mb-30-px{
  margin-bottom: 30px;
}
.mr-40{
  margin-right: 40px;
}
.m-aut{
  margin: 0 auto;
}
.w-2{
    width: 2%;
}
.w-4{
  width: 4%;
}
.w-5{
  width: 5%;
}
.w-10-px{
  width: 10px;
}
.w-100-px{
  width: 100px;
}
.w-195{
  width: 195px;
}
.w-10{
  width: 10%;
}

.w-15{
    width: 15%;
}
.w-17{
  width: 17%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-33{
  width: 33%;
}
.w-40{
  width: 40%;
}
.w-45{
  width: 45%;
}
.w-48{
  width:48%;
}
.w-49{
    width: 49%;
}
.w-60{
  width: 60%;
}
.w-70{
  width: 70vh;
}
  .w-80{
    width: 80%;
}
.w-85{
  width: 85%;
}
.w-90{
  width: 90%;
}
.fs-10{
    font-size: 10px;
}
.fs-12{
    font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.mt-2-px{
  margin-top: 2px;
}
.mt-5-px{
  margin-top: 5px;
}
.mt-6-px{
    margin-top: 6px;
}
.mt-10-px{
  margin-top: 10px;
}
.mt-15-px{
  margin-top: 15px;
}
.mt-20-px{
  margin-top: 20px;
}
.mt-7{
    margin-top: 7%;
}
.mt-10{
    margin-top: 10%;
}
.h-30{
    height: 30px;
}
.h-34{
    height: 34px;
}
.h-50{
    height: 50px;
}
.h-70{
    height: 70vh;
}
.h-60-px{
  height: 60px;
}
.h-67{
  height: 67;
}
/* .h-77-px{
  height: 77.72px;
}
.w-168-px{
  width: 168.16px;
} */
.h-50-px{
  height: 50px;
}
.h-100-px{
    height: 100px;
}
.h-100{
    height: 100%;
}
.w-5{
    width: 5%;
}
.w-17{
    width: 17%;
}
.w-60{
    width: 60%;
}
.w-66{
    width: 66%;
}
.w-70{
    width: 70px;
}
.w-80{
  width: 80px;
}
.w-85{
  width: 85%;
}
.w-15{
  width: 15%;
}
.w-200{
    width: 200px;
}
.mxw-200{
    max-width: 200px;
}
.bottom-0{
  bottom: 0px;
}
.bottom-20-px{
    bottom: 20px;
}
.r-20-px{
    right: '20px';
}
.b-r-10{
    border-radius: 10px;
}
.b-1-s{
    border: 1px solid silver;
}
.ws-prn{
    white-space: pre-line;
}
.w-80{
    width: 80%;
}
.w-90{
    width: 90%;
}
.ml-0{
  margin-left: 0px;
}

.ml-5-px{
    margin-left: 5px;
  }
.ml-10-px{
    margin-left: 10px;
}
.ml-30-px{
  margin-left: 30px;
}
.mg-ri-110{
  margin-right: 110px;
}
.mg-ri-180{
  margin-right: 180px;
}
.mg-ri-200{
  margin-right: 200px;
}

.mg-lf-110{
  margin-left: 110px;
}
.mg-lf-310{
  margin-left: 310px;
}
.mg-lf-510{
  margin-left: 510px;
}
.ml-20-px{
  margin-left: 20px;
}
.ml-60-px{
  margin-left: 60px;
}
.mr-60-px{
  margin-right: 60px;
}
.mr-120-px{
  margin-right: 120px;
}
.ml-5{
    margin-left: 5%;
}
.ml-20{
    margin-left: 20%;
}
.ml-25{
    margin-left: 25%;
}
.me-sm-5{
    margin-right: 5%;
}
.mt-sm-10{
  margin-right: 10%;
}
.mt-sm-30{
  margin-right: 30px;
}
.mt-10-px{
    margin-top: 10px;
} 
.b-r-5{
  border-radius: 5px;
}
.b-r-10{
    border-radius: 10px;
}
.w-s-prl{
  white-space: pre-line;
}
.b-white{
    background-color: white;
}
.h-30{
    height: 30%;
}
.h-40{
    height: 40%;
}
.h-53-px{
    height: 53px;
}
.fs-10{
    font-size: 10px;
}
.fs-12{
    font-size: 12px;
}
.w-10-px{
    width: 10px;
}
.mx-250-px{
    max-width: 250px;
}
.js-it-l{
    justify-items: left;
}
.mg-t-6px{
    margin-top: 6px;
}
.w-s{
    white-space: pre-line;
}
.m-a{
    margin: auto;
}
.al-it-lft{
    align-items: left;
}
.b-n-r{
  background-repeat: no-repeat;
}
.b-s{
  background-size: contain;
}
.b-p-t{
  background-position: top;
}
.h-42{
  height: 42;
}


/* parte de Pablo */


.mt-sm-10{
    margin-right: 10%;
  }
  .b-c{
    border-color: #52B61C;
  }

.fs-14{
    font-size: 14;
}
.b-n{
    border: "none";
}

.h-70-vh{
    height: 70vh;
  }
  
  .w-70-vh{
    width: 70vh;
  }

.b-rad-10-px{
  border-radius: 10px;
}

.w-40{
  width: 40%;
}

.w-20{
  width: 20%;
}

.w-25{
  width: 25%;
}

.w-55{
  width: 55%;
}



.ms-sm-5{
  margin-left: 5%;
}

.me-sm-5{
  margin-right: 5%;
}

.w-6{
  width: 6;
}
.w-47{
  width: 47%;
}
.w-10-px{
  width: 10px;
}
.w-30{
  width: 30%;
}
.w-70{
  width: 70%;
}
.azul-verdoso-#28BAC1{
  color: #28BAC1;
}
.fs-17{
  font-size: 17;
}
.b-white{
  background-color: white
}
.w-60{
  width: 60%;
}
.bl-bl{
  border-color: #0d6efd;
}
.bl{
  background-color: #0d6efd;
}
.mb-5-px{
  margin-bottom: 5px;
}
.border-bottom-1-px{
  border-bottom: 1px solid #ccc;
}
.fs-14{
  font-size: 14;
}
.w-35{
  width: 35%;
}
.w-20-px{
  width: 20px;
}
.mt-10{
  margin-top: 10%;
}
.pt-pb-10-px{
  padding-top: 10px;
  padding-bottom: 10px;
}
.h-12{
  height: 12%;
}
.h-90{
  height: 90%;
}
.h-10{
  height: 10%;
}
.h-80{
  height: 80%;
}
.p-0-20{
  padding: 0 20px;
}
.p-5-px{
  padding: 5px;
}
.p-10-px{
  padding: 10px;
}
.p-15-px{
  padding: 15px;
}
.p-20-px{
  padding: 20px;
}
.pt-10-px{
  padding-top: 10px;
}
.pt-50-px{
  padding-top: 50px;
}
.fs-35{
  font-size: 35;
}
.color-navbar-1{
  color: #5A5A5A
}
.ff-helvetica{
  font-family: Helvetica;
}
.w-12{
  width: 12%;
}
.mt-15-px{
  margin-top: 15px;
}
.mb-15-px{
  margin-bottom: 15px;
}
.mt-20-px{
  margin-top: 20px;
}
.mt-30-px{
  margin-top: 30px;
}
.min-h-30{
  min-height: 7%;
}
.color-navbar-2{
  color: #484848
}
.h-0{
  height: 0%;
}
.fs-30{
  font-size: 30;
}
.w-15{
  width: 15%;
}
.mb-10-px{
  margin-bottom: 10px;
}
.w-11{
  width: 11%;
}
.max-h-50-px{
  max-height: 50px;
}
.max-h-550-px{
  max-height: 550px;
}
.max-h-55-px{
  max-height: 55px;
}
.pt-200-px{
  padding-top: 200px;
}
.w-200{
  width: 200%;
}
.h-40-px{
  height: 40px;
}
.h-20{
  height: 20%;
}
.h-5{
  height: 5%;
}
.h-28{
  height: 28px;
}
.col-co{
  column-count: 2px;
}
.bt-w{
  border-top-width: 1px;
}
.fs-24-px {
  font-size: 24px;
}
.mw_100 {
  max-width: 100% ;
}

.mg-tp-20 {
  margin-top: 20px ;
}

.mg-bt-20 {
  margin-bottom: 20px ;
}

.mb-50-px{
  margin-bottom: 50px;
}

.bg-colorAzul{
  background-color:#2381B8;
}

.bg-e6{
  background-color: #e6e6fa;
}

/* esto lo de Traxain/blog */
body {
  font-family: Arial, Helvetica, sans-serif;
}

h1,
h2 {
  font-size: 24px;
  color: grey;
  font-weight: light;
}

h3,
h4,
p {
  font-size: 18px;
  color: grey;
  font-weight: light;
  line-height: 1.5;
}

.text-highlight-color {
  color: #0d6efd;
}

.bg-highlight-color {
  background-color: #0d6efd;
  color: white;
}

.text-intend-0p {
  text-indent: 0px;
}

.text-intend-30p {
  text-indent: 30px;
}

.fs-22 {
  font-size: 22px;
}

.fs-22nav {
  font-size: 22px;
}

/* Estilos para pantalla pequeña */
@media (max-width: 1280px) {
  .fs-22nav {
    font-size: 16px;
  }
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.fs-70 {
  font-size: 70px;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-92 {
  width: 92%;
}

.w-20p {
  width: 20px;
}

.w-140p {
  width: 140px;
}

.w-180p {
  width: 180px;
}

.w-80p {
  width: 80px;
}

.w-100p {
  width: 100px;
}

.w-300p {
  width: 300px;
}

.w-calc {
  width: calc(100% / 3);
}

.min-h-350 {
  min-height: 350px;
}

.min-h-450 {
  min-height: 450px;
}

.min-h-550 {
  min-height: 550px;
}

.h-70 {
  height: 70%;
}

.h-85 {
  height: 85%;
}

.h-90 {
  height: 90%;
}

.h-10p {
  height: 10px;
}

.h-60p {
  height: 60px;
}

.h-100p {
  height: 100px;
}

.h-300p {
  height: 300px;
}

.h-400p {
  height: 400px;
}

.va-top {
  vertical-align: top;
}

.ptl-0 {
  padding: 0px;
}

.ptl-3 {
  padding: 3px;
}

.ptl-5 {
  padding: 5px;
}

.ptl-8 {
  padding: 8px;
}

.ptl-10 {
  padding: 10px;
}

.ptl-15 {
  padding: 15px;
}

.ptl-20 {
  padding: 20px;
}

.ptl-0 {
  padding: 0px;
}

.mtl-0 {
  margin: 0px;
}

.pl-15 {
  padding-left: 15px;
}
.pl-30 {
  padding-left: 30px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-15 {
  padding-bottom: 15px;
}
.pb-30 {
  padding-bottom: 30px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-50 {
  margin: 50px;
}

.ml-20 {
  margin-left: 20%;
}

.ml-65p {
  margin-left: 65px;
}

.mr-20 {
  margin-right: 20%;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-30p {
  margin-left: 30px;
}

.z-50 {
  z-index: 50;
}

.footer-border-top {
  border-top: 1px solid #ddd;
}

.border-table {
  border: 1px solid #ccc;
}

.border-table:hover {
  background-color: #3f91d0;
}

.border-table:hover .blog-title {
  color: black;
}

.border-collapse {
  border-collapse: collapse;
}

.transition-bg-color-03 {
  transition: background-color 0.3s;
  background-color: white;
}

.bg-white {
  background-color: white;
}

.clickable {
  cursor: pointer;
}

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* General */
}

.div-pegado {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.box-shadow {
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  color: #ff0000;
}

.bg-img-body {
  background-image: url("../../public/fondoprincipal.png");
  background-size: cover;
}

.bg-img-fondo {
  background-image: url("../../public/camionesaparcados.webp");
  background-size: cover;
  min-height: 350px;
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-img-camion {
  background-image: url("../../public/camion.webp");
  background-size: cover;
  min-height: 350px;
  border-radius: 10px;
}

.bg-img-rrhh {
  background-image: url("../../public/rrhh.webp");
  background-size: cover;
  min-height: 350px;
  border-radius: 10px;
}

.bg-img-drivers {
  background-image: url("../../public/drivers.webp");
  background-size: cover;
  min-height: 350px;
  border-radius: 10px;
}

.logo-rs {
  transform: scale(0.6);
  filter: drop-shadow(2px 2px 4px rgba(1, 1, 1, 1));
  transition: transform 0.3s ease-in-out;
}

.logo-rs:hover {
  transform: scale(0.9);
  transition: transform 0.3s ease-in-out;
}

.text-align-justify {
  text-align: justify;
}

/* Aquí termina el estilo de Traxain/blog */

h1, h3, h5{
  color: #5A5A5A;
}
.clickable-element {
  cursor: pointer;
}

.clickable-element:hover {
  background-color: #e9e9e9;
}

.mw_100 {
  max-width: 100% ;
}

.mg-tp-20 {
  margin-top: 20px ;
}

.mg-tp-10 {
  margin-top: 10px ;
}

.mg-bt-10 {
  margin-bottom: 10px ;
}

.mg-bt-20 {
  margin-bottom: 20px ;
}

.mg-lef-10 {
  margin-left: 10px ;
}

.mg-ri-20 {
  margin-right: 20px ;
}

.fs-6 {
  font-size: 6px ;
}

.fs-12 {
  font-size: 12px ;
}

.fs-14 {
  font-size: 14px ;
}

.fs-16 {
  font-size: 16px ;
}

.fs-17 {
  font-size: 17px ;
}

.fs-20 {
  font-size: 20px ;
}

.fs-22 {
  font-size: 22px ;
}

.fs-24 {
  font-size: 24px ;
}

.d-p-flex {
  display: flex;
}

.align-it-center {
  align-items: center ;
}

.ali-con-center {
  align-content: center ;
}

.f-d-col {
  flex-direction: column ;
}

.j-c-cen {
  justify-content: center ;
}

.te-ali-cen {
  text-align: center;
}

.te-ali-lef {
  text-align: left;
}

.te-ali-rig {
  text-align: right;
}

.wid-100{
  width:100%;
}

.wid-90{
  width:90%;
}

.wid-80{
  width:80%;
}

.wid-66{
  width:66%;
}

.wid-60{
  width:60%;
}

.wid-50{
  width:50%;
}

.wid-47{
  width:47%;
}

.wid-45{
  width:45%;
}

.wid-40{
  width:40%;
}

.wid-33{
  width:33%;
}

.wid-30{
  width:30%;
}

.wid-25{
  width:25%;
}


.wid-20{
  width:20%;
}


.wid-17{
  width:17%;
}

.wid-15{
  width:15%;
}

.wid-10{
  width:10%;
}

.wid-5{
  width:5%;
}

.wid-2{
  width:2%;
}

.dis-flex {
  display: flex ;
}

.fle-colu {
  flex-direction: column ; 
}

.white-bg {
  background-color: white;
}

.fw-lig {
  font-weight: 300;
}

.fw-med{
  font-weight: 500 ;
}

.ver-ali-top {
  vertical-align: top;
}

.he-100 {
  height: 100%;
}


.he-60 {
  height: 60%;
}

.he-20 {
  height: 20%;
}

.pad-top-8 {
  padding-top: 8px;
}

.pad-rig-16 {
  padding-right: 16px;
}

.bn {
  border: none;
}

.no-border {
  border: none ;
  outline: none ;
  box-shadow: none ;
  border-radius: 0px ;
  border-color: #e6e6fa;
  color: #fd0e35;
}


.react-dropdown-container {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent; /* Optional: Adjust the background if needed */
}
.react-dropdown-option {
  border: none !important;
}