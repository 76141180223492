h1, h3, h5{
  color: #5A5A5A;
}
.clickable-element {
  cursor: pointer;
}

.clickable-element:hover {
  background-color: #e9e9e9;
}

.mw_100 {
  max-width: 100% ;
}

.mg-tp-20 {
  margin-top: 20px ;
}

.mg-tp-10 {
  margin-top: 10px ;
}

.mg-bt-10 {
  margin-bottom: 10px ;
}

.mg-bt-20 {
  margin-bottom: 20px ;
}

.mg-lef-10 {
  margin-left: 10px ;
}

.mg-ri-20 {
  margin-right: 20px ;
}

.fs-6 {
  font-size: 6px ;
}

.fs-12 {
  font-size: 12px ;
}

.fs-14 {
  font-size: 14px ;
}

.fs-16 {
  font-size: 16px ;
}

.fs-17 {
  font-size: 17px ;
}

.fs-20 {
  font-size: 20px ;
}

.fs-22 {
  font-size: 22px ;
}

.fs-24 {
  font-size: 24px ;
}

.d-p-flex {
  display: flex;
}

.align-it-center {
  align-items: center ;
}

.ali-con-center {
  align-content: center ;
}

.f-d-col {
  flex-direction: column ;
}

.j-c-cen {
  justify-content: center ;
}

.te-ali-cen {
  text-align: center;
}

.te-ali-lef {
  text-align: left;
}

.te-ali-rig {
  text-align: right;
}

.wid-100{
  width:100%;
}

.wid-90{
  width:90%;
}

.wid-80{
  width:80%;
}

.wid-66{
  width:66%;
}

.wid-60{
  width:60%;
}

.wid-50{
  width:50%;
}

.wid-47{
  width:47%;
}

.wid-45{
  width:45%;
}

.wid-40{
  width:40%;
}

.wid-33{
  width:33%;
}

.wid-30{
  width:30%;
}

.wid-25{
  width:25%;
}


.wid-20{
  width:20%;
}


.wid-17{
  width:17%;
}

.wid-15{
  width:15%;
}

.wid-10{
  width:10%;
}

.wid-5{
  width:5%;
}

.wid-2{
  width:2%;
}

.dis-flex {
  display: flex ;
}

.fle-colu {
  flex-direction: column ; 
}

.white-bg {
  background-color: white;
}

.fw-lig {
  font-weight: 300;
}

.fw-med{
  font-weight: 500 ;
}

.ver-ali-top {
  vertical-align: top;
}

.he-100 {
  height: 100%;
}


.he-60 {
  height: 60%;
}

.he-20 {
  height: 20%;
}

.pad-top-8 {
  padding-top: 8px;
}

.pad-rig-16 {
  padding-right: 16px;
}